.body--modal-open {
  overflow: hidden;
}

a {
  text-decoration: none;
}

.StripeElement--webkit-autofill {
  background-color: transparent !important;
}
